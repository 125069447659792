<div class="external-box">
  <ngx-loading-bar [includeSpinner]="false" [color]="'#ff8c00'" [height]="'3px'"></ngx-loading-bar>
  <app-toast aria-live="polite" aria-atomic="true"></app-toast>
  <div class="container d-flex flex-column">
    <app-header [institution]='Institution.csf'></app-header>
    <app-navbar [institution]='Institution.csf'></app-navbar>
    <router-outlet class="mt-2 wrapper"></router-outlet>
    <app-footer [institution]='Institution.csf'></app-footer>
  </div>
</div>
