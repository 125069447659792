import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpRequestInterceptor} from './shared/services/http-request-interceptor.service';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from 'ngx-cookieconsent';
import {BrowserModule} from '@angular/platform-browser';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxJsonLdModule} from 'ngx-json-ld';
import {JsonLdModule} from 'ngx-seo';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#646464',
    },
    button: {
      background: '#0ccc49',
    }
  },
  theme: 'edgeless',
  type: 'opt-out',
  content: {
    message: 'Questa piattaforma utilizza esclusivamente cookie tecnici necessari per il funzionamento del sito, per l\'utilizzo non serve il tuo consenso;\n' +
      'Cliccando “Accetta tutto” o "Rifiuta e chiudi" verranno installati solo i cookie tecnici. Per saperne di più consulta la nostra ' +
      '<a target="_blank" class=\"cc-link\" href="https://www.csf-formazione.it/csf-professionisti-in-formazione/cookie-policy.html">Informativa Cookies</a>',
    href: 'https://www.csf-formazione.it/informativa-sulla-privacy.html',
    link: 'Informativa Privacy',
    allow: 'Accetta tutto',
    deny: 'Rifiuta e chiudi'
  }
};

const cookiePath = (): string => {
  return '';
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxJsonLdModule,
    JsonLdModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
