<div class="header-csf" *ngIf="institution === Institution.csf">
  <a href="https://www.csfapl.it/">
    <div class="logo-csf"></div>
  </a>
</div>

<div class="header-insieme" *ngIf="institution === Institution.insieme">
  <a href="https://www.insiemeformiamo.it/">
    <div class="logo-insieme"></div>
  </a>
</div>
