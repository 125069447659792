import {Component, Input, OnInit} from '@angular/core';
import {Institution} from '../../enum/Institution';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  Institution = Institution;
  @Input() institution?: Institution;

  constructor() { }

  ngOnInit(): void {}


}
