import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {CsfComponent} from './csf/csf.component';
import {InsiemeComponent} from './insieme/insieme.component';

const routes: Routes = [
  { path: 'csf', loadChildren: () => import('./csf/csf.module').then(m => m.CsfModule) ,
    component: CsfComponent},
  { path: 'insieme', loadChildren: () => import('./insieme/insieme.module').then(m => m.InsiemeModule),
    component: InsiemeComponent},
  { path: '', redirectTo: 'csf', pathMatch: 'full'},
  { path: '**', redirectTo: 'csf', pathMatch: 'full'},
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
