import {Component, Inject, OnInit} from '@angular/core';
import {Institution} from '../shared/enum/Institution';

@Component({
  selector: 'app-csf',
  templateUrl: './csf.component.html',
  styleUrls: ['./csf.component.scss']
})
export class CsfComponent implements OnInit {

  Institution = Institution;
  constructor(
  ) { }

  ngOnInit(): void {
    const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    favIcon.href = `/assets/img/favicon-csf.ico`;
  }

}
