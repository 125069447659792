import {Component, Input, OnInit} from '@angular/core';
import {faBook, faGavel, faUserSecret, faPaw, faEye} from '@fortawesome/free-solid-svg-icons';
import {Institution} from '../../enum/Institution';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear = new Date().getFullYear();
  Institution = Institution;
  @Input() institution?: Institution;

  faBook = faBook;
  faGavel = faGavel;
  faUserSecret = faUserSecret;
  faPaw = faPaw;
  faEye = faEye;
  constructor() { }

  ngOnInit(): void {
  }

}
