<nav class="navbar-csf navbar navbar-expand-lg navbar-light bg-light mt-2" *ngIf="institution === Institution.csf">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Home</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a href="https://www.csf-formazione.it/" class="nav-link">CSF Formazione</a>
        </li>
        <li class="nav-item">
          <a href="https://www.csfapl.it" class="nav-link">CSF Agenzia per il Lavoro</a>
        </li>
      </ul>
    </div>
  </div>
</nav>



<nav class="navbar-insieme navbar-expand-lg navbar-light bg-light mt-2 navbar-container" *ngIf="institution === Institution.insieme">
  <div class="container-fluid menu-container">
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav fw-bold">
        <li class="nav-item ms-3 me-3">
          <a href="https://insiemeformiamo.it/" class="nav-link">Home</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
