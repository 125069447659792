<div class="p-4" [ngClass]="{'footer-csf' : institution === Institution.csf, 'footer-insieme' : institution === Institution.insieme }">
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container-fluid">
      <div *ngIf="institution === Institution.csf" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" aria-current="page" href="https://www.csfapl.it/carta-dei-servizi"><fa-icon [icon]="faBook"></fa-icon> Carta dei Servizi</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.csfapl.it/codice-etico"><fa-icon [icon]="faGavel"></fa-icon> Codice Etico</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.csfapl.it/informativa-privacy"><fa-icon [icon]="faUserSecret"></fa-icon> Informativa Privacy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.csfapl.it/informativa-sui-cookies"><fa-icon [icon]="faPaw"></fa-icon> Informativa sui Cookies</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.csfapl.it/amministrazione-trasparente"><fa-icon [icon]="faEye"></fa-icon> Amministrazione Trasparente</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <hr />
  <p *ngIf="institution === Institution.csf">
    <b>CSF Centro Servizi e Formazione srl</b> - Via Signorelli, 27 - 80017 Melito di Napoli (NA) - P.IVA 05124921213 <br>
    Iscritta in data 7/10/2016 nell'Albo Informatico delle Agenzie per il Lavoro, m_lps.39.REGISTRO UFFICIALE.USCITA.0014394.04-10-2016 <br>
    Accreditato presso il Ministero della Giustizia come ente di formazione per la mediazione con il numero 69 - Provvedimento del 17 marzo 2010 <br>
    Ente di formazione accreditato presso la Regione Campania al n. 1761
  </p>
  <p *ngIf="institution === Institution.insieme">
    <b>Insieme srl Impresa Sociale</b><br>
    sede legale: Via Alcide De Gasperi. 33 - 80133 Napoli - P. IVA: 09241591214<br>
    sede operativa: Via Signorelli, 35 - 80017 Melito di Napoli (NA)<br>
    Ente di formazione accreditato presso la Regione Campania - codice ente 03090/12/19
  </p>
  <div class="footer-links">
    <a href="https://jigsaw.w3.org/css-validator/check/referer?profile=css3">CSS Valid</a> | <a href="https://validator.w3.org/check?uri=referer">XHTML Valid</a> | <a href="#">Top</a>
  </div>
  <p *ngIf="institution === Institution.csf" class="mt-2">
    Copyright © CSF Centro Servizi e Formazione Srl - {{currentYear}} All rights reserved. <a href="https://www.insightagency.info/">Web Design by InsightAgency</a>
  </p>
  <p *ngIf="institution === Institution.insieme" class="mt-2">
    Copyright © Insieme srl Impresa Sociale - {{currentYear}} All rights reserved. <a href="https://www.insightagency.info/">Web Design by InsightAgency</a>
  </p>

</div>

