import {Component, Inject, OnInit} from '@angular/core';
import {Institution} from '../shared/enum/Institution';

@Component({
  selector: 'app-insieme',
  templateUrl: './insieme.component.html',
  styleUrls: ['./insieme.component.scss']
})
export class InsiemeComponent implements OnInit {

  Institution = Institution;
  constructor(
  ) { }

  ngOnInit(): void {
    const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    favIcon.href = `/assets/img/favicon-insieme.jpeg`;

    const cookieConsent: HTMLLinkElement = document.querySelector('.cc-link');
    cookieConsent.href = `https://insiemeformiamo.it/2-uncategorised/140-cookie-policy.html?ml=1`;
  }

}
